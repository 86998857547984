.contact{
    background-image: url('../images/contact_bg_mobile.jpg');
    background-size:cover;
    background-position:center bottom;
    padding-top:75px;
    padding-bottom:150px;
    margin-top:-10px;

    @media(min-width:576px){
        background-image: url('../images/contact_bg.jpg');
    }
    .innerContact{
        margin: 5px;
        padding: 35px 20px;
        background-color: rgba(255,255,255,.5);

        .adresWrap{
            .item{
                //display:flex;
                align-items: center;
                margin-top:25px;

                @media(min-width:768px){
                    margin-top:35px;
                }

                strong,p,a{
                    margin:0;
                    font-size:16px;
                    color:#333232;
                }

                img{
                    margin-right:15px;
                }

                p{
                    
                }

            }
        }
    }
}