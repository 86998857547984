.headSection{
    color:$headSection;
    h4{
        margin:0;
        @include primary-font(700);
        font-size:25px;

        @media(min-width:768px){
            font-size:27px;
        }

        @media(min-width:1400px){
            font-size:30px;
        }
    }
    h3{
        @include primary-font(900);
        font-size:35px;
        margin:12px 0;
        line-height:38px;

        @media(min-width:768px){
             font-size:40px;
             line-height:46px;
        }

        @media(min-width:1400px){
            font-size:45px;
            margin: 9px 0;
       }

        &:after{
            display:block;
            content:'';
            width:80px;
            height:7px;
            background-color: $orange;
            margin-top:18px;
        }
    }

    .textBox{

    }
}