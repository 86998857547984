.ourHouse{
    background-image:url('../images/our_home_bg_top.png');
    background-position: center top;
    background-repeat:no-repeat;
    margin-top: -35px;
    position: relative;
    z-index: 10;
    padding-top:120px;
    padding-bottom:120px;
    
    @media (max-width:500px){
        padding-bottom:0px!important;
    }
    .photoBox{
        &__booking{
            margin-top: 2.3rem;
            display: flex;
            width: 100%;
            .left, .right{
                width: 50%;
                text-align: center;
            }
            img{
                width: 140px!important;
                height: 140px!important;
                text-align: center;
            }
            h5{
                margin-top: .3rem;;
                color: #333232;
                font-size: 11px;
                a{
                    color: #333232;
                    font-size: 11px;
                }
                span{
                    font-size: 16px;
                    font-weight: 700;
                    display: block;
                }
            }
        }
    }
}