/**
 * Basic typography style for copy text
 */
body {
  color: $text-color;
  font: normal 125% / 1.4 $text-font-stack;
  @include primary-font(500);
}

section{
  padding-top:50px;
  padding-bottom:50px;
}

