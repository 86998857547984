.moreServicesBox{

    &::before{
        position: absolute;
        top: -20px; 
        content:'';
        display:block;
        height:70px;
        width:100%;
        background-image:url('../images/moreServices_before_bg.png');
    }

    &::after{
        display: none!important;
        position: absolute;
        bottom: 10px;    
        content:'';
        display:block;
        height:70px;
        width:100%;
        background-image:url('../images/moreServices_after_bg.png');
    }

    .moreServices{
        background-color: #efecec;
        position:relative; 
    }
}
.modal .mainTextSection h5{
    font-weight: 700;
    cursor: pointer;
}