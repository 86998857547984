// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

form{
    margin-top:30px;

    .form-group{
        label{
            color:#5c5c5c;
            font-weight: 300;
            font-size:16px;
        }
    
        input{
            font-size: 18px;
            padding: 22px 12px;
            transition:.3s;

            &:focus{
                padding: 22px 22px;
                border:1px solid transparent;
            }
        }

        textarea{
            font-size: 18px;
            padding:15px;
            transition:.3s;

            &:focus{
                padding: 15px 22px;
                border:1px solid transparent;
            }
        }
    }

    span.wpcf7-not-valid-tip{
        font-size:14px;
    }

    div.wpcf7-response-output{
        font-size:16px;
        text-align:center !important;
        border:0 !important;
        color:#ff0000;
    }

    .wpcf7-list-item{
        margin:0 5px 0 0 !important;
    }

    .rodoText{
        .text{
            font-size:11px;
            margin-top:5px;
        }

        a{
            &:hover{
                cursor:pointer;
            }
        }
    }
}