.mainSlider{
    position: absolute;
    top:0;
    left:0;
    .item{
        width:100%;
        height:100vh;
        background-image:url('../images/slide1.jpg');
        background-size:cover;
        background-position:center;
        position:relative;
    }   

    .owl-dots{
        position: absolute;
        bottom:70px;
        text-align: center;
        width: 100%;
        .owl-dot{
            width:15px;
            height:15px;
            border-radius:50%;
            background: rgba(255,255,255,.5);
            margin:3px;

            &.active{
                background: rgba(255,255,255,1);
            }

            @media (min-width:768px){
                width:25px;
                height:25px;
                margin:5px;
            }
        }

        //special style
        @media(max-width:800px) and (max-height:450px) and (min-width:550px) and (min-height:320px){
            bottom:50px;
        }
    }
   
}