// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

footer{
    background-color: $footerBg;
    padding-top:15px;
    padding-bottom:80px;

    .nav{
        .nav-link{
            font-size:14px;
            color:#fff;
            font-weight: bold;
            letter-spacing: .9px;

            &:hover{
                text-decoration: underline;
                cursor: pointer;
            }

            img{
                margin:2px;
                &:hover{
                    cursor:pointer;
                }
            }
        }
    }
}

.footerBottom{
    padding-top:30px;
    background-image: url('../images/footerBottom_bg.jpg');
    background-position:top center;
    background-repeat: no-repeat;
    p,a{
        color:#4e4e4e !important;
        font-size:14px;
    }

    a{
        &:hover{
            cursor:pointer !important;
            text-decoration: underline !important;
        }
    }
}