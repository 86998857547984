.Atrakcje{
    &__mainTextSection{
        margin-top: 2rem;
        p{
            font-size: 13px;
            font-weight: 100;
        }
    }
    .AtrakcjeSlider{
        .item{
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 400px;
        }
    }
}