
	.parallax {        
        /* Set a specific height */
        min-height: 60vh;   
        /* Create the parallax scrolling effect */
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        .titleSection {
            color: #fff;
        }
    }