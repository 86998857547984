// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

header{
    height:100vh;
    position:relative;

    .mainText{
        position: absolute;
        z-index: 2;
        left:50%;
        top:50%;
        transform: translate(-50%, -50%);
        width:100%;
        text-align: center;
        color:#fff;
        padding:0 15px;

        h1{
            margin:0;
            font-size:42px;
            @include primary-font(900);
            text-shadow: 1px 1px 3px rgba(0,0,0,0.61);

            @media (min-width:768px){
                font-size:55px;
            }
            @media (min-width:992px){
                font-size:60px;
            }
            @media (min-width:1400px){
                font-size:70px;
            }

            //special style
            @media(max-width:800px) and (max-height:450px) and (min-width:550px) and (min-height:320px){
                font-size: 35px;
            }
        }

        h2{
            margin:15px 0;
            font-size:27px;
            //font-size:40px;
            @include primary-font(700);
            text-shadow: 1px 1px 3px rgba(0,0,0,0.61);

            @media (min-width:768px){
                font-size:32px;
                margin:20px 0;
            }

            @media (min-width:1400px){
                font-size:40px;
                margin:25px 0;
            }

            //special style
            @media(max-width:800px) and (max-height:450px) and (min-width:550px) and (min-height:320px){
                margin: 5px 0;
                font-size: 23px;
            }
        }

        button{
            
            //special style
            @media(max-width:800px) and (max-height:450px) and (min-width:550px) and (min-height:320px){
                margin-top:15px;
            }
        }
    }
}