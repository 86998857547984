// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $brand-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}
b, strong {
  font-weight: bold!important;
}
.video{
  background-color: #EFECEC;
}
img.Justyna{
	max-width: 100%;
}
.widgetBoxSM {
  position: fixed;
  z-index: 10000;top: 150px;
  right: 0;
  display: block;
  width: 50px;
  padding: 10px 0 10px 10px;
  background: #FFFFFF8F 0% 0% no-repeat padding-box;
  border-radius: 12px 0 0 12px;
  opacity: 1;  
  @media (max-width:500px){
    width: 35px;
    padding: 5px 0 5px 5px;
  }
  img {
    margin-top: 10px;
  }
}

@media (max-width:500px){
  section {
    padding-top: 20px!important;
    padding-bottom: 20px!important;
  }
  .min-vh-100 {
    min-height: 80vh !important;
  }
  .gallery {
    overflow: hidden;
    padding-top: 0!important;
    padding-bottom: 0!important;
  }
}