// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
    font-family: 'Museo Sans 900';
    src: url('../fonts/MuseoSans-900.woff2') format('woff2'),
        url('../fonts/MuseoSans-900.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}//font-family: 'Museo Sans 900';

@font-face {
    font-family: 'Museo Sans 700';
    src: url('../fonts/MuseoSans-700.woff2') format('woff2'),
        url('../fonts/MuseoSans-700.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}//font-family: 'Museo Sans 700';

@font-face {
    font-family: 'Museo Sans 500';
    src: url('../fonts/MuseoSans-500.woff2') format('woff2'),
        url('../fonts/MuseoSans-500.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}//font-family: 'Museo Sans 500';

@font-face {
    font-family: 'Museo Sans 100';
    src: url('../fonts/MuseoSans-100.woff2') format('woff2'),
        url('../fonts/MuseoSans-100.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}//font-family: 'Museo Sans 100';






