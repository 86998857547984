nav{
    padding:0 15px !important;
    background-color:rgba(255,255,255,.8);
    color:$navColor;
    position: inherit !important;
    transition:.2s;

    @media (min-width:768px){
        background-color:transparent;
    }

    .navbar-brand{


        @media(min-width:768px){
            position: fixed;
            top: 3px;
            left: 3%;
            z-index: 3;
        }

        img{

            transition:.2s;
            max-width:80px;

            &:hover{
                cursor:pointer;
            }

            @media (min-width:768px){
                max-width:120px;
            }
            @media (min-width:992px){
                max-width:160px;
            }
            @media (min-width:992px){
                max-width:180px;
            }

             //special style
             @media(max-width:800px) and (max-height:450px) and (min-width:550px) and (min-height:320px){
                max-width: 60px;
            }
        }
    }

    .nav-item{
        a{
        display: block;
        color: #333232;
        font-size:14px;
        padding:7px 10px;
        @include primary-font(900);

        &:hover,
        &:focus,
        &:active,
        &:visited{
            cursor:pointer;
            text-decoration: none !important;
            color: #666
        }

        &:before{
            background:$orange !important;
        }

        @media (min-width:768px){
            font-size:15px;
            padding: 13px 12px;
            }
        }
    }

    .navbar-toggler{
        img{
            max-width:28px;
        }
    }

    .menuNav{
        transition:.2s;
        width:100%;
        @media (min-width:768px){
            background-color:rgba(255,255,255,.8);
            position:fixed;
            top:40px;
        }
    }

    .fastContact{
        font-size:15px;
        vertical-align: middle;
        padding:0 8px;
        margin-top:10px;

        @media (min-width:768px){
            margin-top:0;
            padding-top: 8px;
        }

        strong{
            margin-right:10px;
        }

        img{
            margin:0 2px;
        }

        a{
            color:$navColor;
        }
    }

    &.activeTop{
        position:fixed !important;
        width:100%;

        
        @media (min-width:768px){
            position: inherit !important;

        }


        .navbar-brand{
            top:0;
            padding:2px;
            margin:0;

            @media(min-width:768px){
                position: fixed;
                top: 3px;
                left: 3%;
                z-index: 3;
            }
    
            img{
                max-width:60px;
    
                @media (min-width:768px){
                    max-width:80px;
                }
                @media (min-width:992px){
                    max-width:80px;
                }
                @media (min-width:992px){
                    max-width:80px;
                }
            }
        }

        .menuNav{
           top:0;
           @media(min-width:768px){
                top: 22px;
           }
        }
    }

}
.footer {
    .nav-link {
        a,
        a:hover,
        a:focus,
        a:active,
        a:visited{
            cursor:pointer;
            text-decoration: none !important;
            color: #fff!important
        }
    }
    &__socialmedia{
        margin-top: 1rem;
        color: #fff;
        font-size: 15px;
        align-items: center;
        ul{
            align-items: center;
        }
        .txt {
            margin-right: .5rem;
        }
        img {
            width: 50px;
            height: 50px;
        }
    }
}