// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.normalBtn,
.moreBtn{
    background-color: $orange;
    color:#fff;
    padding:14px 30px;
    @include primary-font(700);
    font-size:14px;
    letter-spacing: 1px;
    opacity:.9;
    &:hover{
        color:#fff;
        opacity:1;
    }
    &--black{
        background-color: #333232!important;
    }
}

.moreBtn{
    padding:12px 45px 15px 30px;
    @media (min-width:992px){
        font-size:16px!important;        
    }
    @media (max-width:772px){
        padding:12px 35px 15px 25px!important;
        font-size:11px!important;        
    }
    @media (max-width:475px){
        padding:12px 35px 15px 25px!important;
        font-size:11px!important;        
    }
    @media (max-width:375px){
        padding:12px 35px 15px 25px!important;
        font-size:10px!important;        
    }
    
    background-image:url('../images/btn_right_icon.png');
    background-position: center right 13px;
    background-repeat: no-repeat;
    a{
        color:#fff;
        &:hover,
        &:focus,
        &:active{
            color:#fff;
            text-decoration: none;
        }
    }
}

.collapseBtn{
    background-color: #fff;
    color:$orange;
    @include primary-font(700);
    font-size:14px;
    letter-spacing: 1px;
    opacity:.9;
    padding:12px 45px 15px 30px;
    border: 2px solid #fff;    
    background-image:url('../images/pokaz_wiecej.svg');
    background-position: center right 13px;
    background-repeat: no-repeat;
    background-size: 25px 25px;
    width: 230px;
    &--active{
        background-image:url('../images/pokaz_mniej.svg');
    }

    @media (min-width:992px){
        font-size:16px;
        
    }

    &:hover{
        border: 2px solid $orange;
        color:$orange;
        opacity:1;
    }
}

